import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconModule } from '@teamfoster/sdk/icon';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  standalone: true,
  imports: [CommonModule, IconModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertComponent {
  @Input() status?: 'danger' | 'warning' | 'info' | 'success' | 'error' = 'info';
  @Input() message?: string = '';
  @Input() showDefaultIcon = true;
}
